<template>
<ion-content class="ion-padding" :fullscreen="true">
  <div class="container">
    <h2 class="bold text-center">Verifikasi No. Handphone</h2>
    <div class="text-sm text-center">
      Masukkan kode OTP yang dikirim ke nomor whatsapp Anda <b>{{ $filters.maskPhone($auth.phone) }}</b>
    </div>
    <div class="flex flex-center">
      <OtpInput @complete="otpOnComplete" />
    </div>
    <div class="resend text-center text-sm">
      Tidak menerima kode verifikasi? <a @click.prevent="resend" class="link">Kirim ulang</a>
    </div>
    <div class="text-sm">
      Code OTP hanya mengirim ke aplikasi whatsapp, pastikan nomor anda telah terdaftar di aplikasi whatsapp.
    </div>
  </div>
</ion-content>
</template>

<script>
import { IonContent, modalController, toastController  } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import OtpInput from '@/components/OtpInput'

export default defineComponent({
  components: {
    IonContent,
    OtpInput
  },
  data () {
    return {
      //
    }
  },
  methods: {
    async close() {
      await modalController.dismiss()
    },
    async resend () {
      let res = await this.$api.post('/api/send-otp')
      if(! res.error)
        toastController
          .create({
            message: res.message,
            duration: 2000,
            color: 'success',
          }).then(r => r.present())
    },
    async otpOnComplete(code) {
      let res = await this.$api.post('/api/phone-verification', { code: code })
      if(! res.error)
        this.$store.dispatch('auth/fetchUser')
        await modalController.dismiss()
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
})
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
  font-weight: bold;
}

.btn {
  width: 100%;
  &:not(:last-child) {
    margin-right: 15px;
  }

  &.transparent {
    box-shadow: none;
    color: #000;
    &.button-round {
      --box-shadow: none !important;
    }
  }
}
.otp-input {
  width: 50px;
  height: 50px;
  background: #fff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}
.link {
  color: #000;
  cursor: pointer;
  font-weight: bold;
}
</style>
