<template>
  <ion-page>
    <Header title="Klaim Hadiah" />
    <ion-content :fullscreen="true">
      <div class="container" v-if="coupon">
        <Coupon :coupon="coupon" :status="false" />
        <div class="a1s text-sm mt-2">Kode kupon</div>
        <div class="code bold mb-2">
          {{ coupon.coupon_number }}
        </div>
        <div class="divinder"></div>
          <div class="s12sh mt-2">
          <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.6667 25V45.8333H8.33337V25" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M45.8333 14.5834H4.16663V25H45.8333V14.5834Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25 45.8334V14.5834" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25 14.5833H15.625C14.2436 14.5833 12.9189 14.0346 11.9421 13.0578C10.9654 12.0811 10.4166 10.7563 10.4166 9.37496C10.4166 7.99362 10.9654 6.66886 11.9421 5.69211C12.9189 4.71536 14.2436 4.16663 15.625 4.16663C22.9166 4.16663 25 14.5833 25 14.5833Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M25 14.5833H34.375C35.7563 14.5833 37.0811 14.0346 38.0579 13.0578C39.0346 12.0811 39.5833 10.7563 39.5833 9.37496C39.5833 7.99362 39.0346 6.66886 38.0579 5.69211C37.0811 4.71536 35.7563 4.16663 34.375 4.16663C27.0833 4.16663 25 14.5833 25 14.5833Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
          <div class="sx mt-2">
            <div class="bold">Selamat, Anda menang!</div>
            <div class="text-sm">Isi data diri dengan benar untuk klaim hadiah</div>
          </div>
        </div>
        <div class="form mt-1">
          <label for="phone">No. Handphone</label>
          <ion-input type="text" class="input" placeholder="08xxxxxxx" v-model="$auth.phone" :readonly="$auth.phone_verified"></ion-input>

          <div class="flex justify-content-between">
            <label for="name">Alamat Pengirim</label>
            <a class="clone text-sm" @click="clone">Gunakan alamat utama </a>
          </div>
          <ion-textarea v-model="address" class="input"></ion-textarea>

          <label for="phone">Kota/Kabupaten</label>
          <ion-input type="text" class="input" v-model="city"></ion-input>
          <!-- <ion-select interface="action-sheet" v-model="city" ok-text="OK" cancel-text="Cancel">
            <ion-select-option :value="c.name" v-for="c in cities" :key="c.id">
              {{ c.name }}
            </ion-select-option>
          </ion-select> -->

          <label for="phone">Kode Pos</label>
          <ion-input type="text" class="input" v-model="postal_code"></ion-input>

          <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn" @click="claim" :disabled="(!$auth && coupon) || sending">Klaim Hadiah</ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonInput, IonTextarea, IonButton, toastController, modalController } from '@ionic/vue';
import Header from '@/components/headerPage';
import Coupon from '@/components/coupon';
import { mapGetters } from 'vuex'
// import regencies from '@/plugins/regencies.json'
import ModalClaimSuccess from '@/components/claim-success'
import ModalVerification from '@/components/phone-verification'

export default {
  components: {
    IonContent,
    IonPage,
    IonInput,
    IonTextarea,
    IonButton,
    Coupon,
    Header
  },
  data() {
    return {
      coupon: null,
      address: null,
      city: null,
      postal_code: null,
      sending: false,
      // cities: []
    }
  },
  created() {
    this.checkCoupon()
    // this.cities = regencies
  },
  watch: {
    '$route' (from, to) {
      if(to.name == 'Claim') {
        this.checkCoupon()
      }
    }
  },
  methods: {
    async checkCoupon() {
      let id = this.$route.params.id
      if(id)
        this.coupon = await this.$api.get(`/api/claim/coupon/${id}`).then(res => res.data)
    },
    async claim(){

      if(! this.$auth.phone_verified) {
        this.phoneverify();
        return;
      }

      if(this.$auth.phone == null || this.address == null || this.city == null || this.postal_code == null || this.phone == ''){
        toastController
          .create({
            message: 'Pastikan data terisi dengan benar.',
            duration: 2000,
            color: 'danger',
          }).then(r => r.present())
          return;
      }

      let data = {
        address: this.address,
        city: this.city,
        postal_code: this.postal_code,
        phone: this.$auth.phone,
        name: this.$auth.name,
        coupon_id: this.coupon.id
      }

      let res = await this.$api.post('/api/claim/coupon', data)
      if(! res.error) {
        let x = await modalController
          .create({
            component: ModalClaimSuccess,
            swipeToClose: true,
            cssClass: 'half-modal'
          })
        return x.present()
      }
    },
    async phoneverify() {
      this.sending = true;
      let res = await this.$api.post('/api/profile', {phone : this.$auth.phone})
      if(! res.error) {
        this.$store.dispatch('auth/fetchUser')
        await this.$api.post('/api/send-otp').then(res => {
          if(! res.error)
            this.sending = false;
        })
        await modalController.create({
          component: ModalVerification,
          swipeToClose: false,
          cssClass: 'half-modal'
        }).then(r => r.present())
      }
    },
    clone() {
      if(! this.$auth.address){
        toastController
          .create({
            message: 'Tidak ada alamat utama',
            duration: 2000,
            color: 'danger',
          }).then(r => r.present())
      }else{
        let address = this.$auth.address
        this.address = address.address
        this.city = address.city
        this.postal_code = address.postal_code
        this.phone = this.$auth.phone
      }
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
}
</script>

<style>

</style>
