<template>
<ion-content class="ion-padding" :fullscreen="true">
  <div class="container text-center map">
    <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.5182 114.17L12.0386 89.2439C10.6325 83.8348 13.8814 78.3021 19.2921 76.8955L155.756 41.4215C161.164 40.0155 166.698 43.2652 168.104 48.6743L174.584 73.6002C166.715 75.6565 161.989 83.7104 164.036 91.5836C166.082 99.4567 174.132 104.189 182.006 102.153L188.486 127.079C189.892 132.488 186.641 138.022 181.233 139.428L44.7691 174.902C39.3584 176.308 33.8264 173.058 32.4202 167.649L25.9406 142.723L25.9546 142.719C33.8332 140.671 38.5685 132.611 36.5202 124.732C34.4719 116.852 26.4107 112.118 18.5321 114.166L18.5182 114.17Z" fill="#007CCD"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M17.4379 110.019L10.9583 85.0935C9.55222 79.6844 12.8011 74.1517 18.2118 72.7451L154.675 37.2711C160.084 35.8651 165.618 39.1148 167.024 44.5239L173.503 69.4498C165.635 71.5061 160.909 79.56 162.955 87.4332C165.002 95.3063 173.052 100.039 180.926 98.0029L187.405 122.929C188.812 128.338 185.561 133.871 180.152 135.277L43.6888 170.751C38.278 172.158 32.746 168.908 31.3399 163.498L24.8603 138.573L24.8743 138.569C32.7528 136.521 37.4882 128.461 35.4399 120.581C33.3916 112.702 25.3303 107.968 17.4518 110.016L17.4379 110.019Z" fill="#0094DD"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M50.8139 64.2756L154.674 37.2769C160.083 35.8709 165.616 39.1206 167.022 44.5297L173.502 69.4556C165.633 71.5119 160.907 79.5658 162.954 87.439C165.001 95.3121 173.05 100.045 180.924 98.0087L187.404 122.935C188.81 128.344 185.56 133.877 180.151 135.283L128.975 148.586C93.8071 144.598 63.0335 119.488 53.6032 83.2109C51.9596 76.888 51.0483 70.5454 50.8139 64.2756Z" fill="#47BDFF"/>
      <rect x="61" y="67" width="74" height="74" rx="37" fill="url(#pattern0)"/>
      <path d="M99.9569 181.647C155.162 181.647 199.914 178.612 199.914 174.869C199.914 171.125 155.162 168.091 99.9569 168.091C44.7522 168.091 0 171.125 0 174.869C0 178.612 44.7522 181.647 99.9569 181.647Z" fill="url(#paint0_radial)"/>
      <path d="M160.222 84.0245C177.281 84.0245 191.111 69.3631 191.111 51.2774C191.111 33.1916 177.281 18.5303 160.222 18.5303C143.163 18.5303 129.333 33.1916 129.333 51.2774C129.333 69.3631 143.163 84.0245 160.222 84.0245Z" fill="#33AF45"/>
      <path d="M160.222 80.112C177.281 80.112 191.111 66.2828 191.111 49.2235C191.111 32.1642 177.281 18.335 160.222 18.335C143.163 18.335 129.333 32.1642 129.333 49.2235C129.333 66.2828 143.163 80.112 160.222 80.112Z" fill="#33CC45"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M155.591 52.1917L148.444 45.0452C147.617 44.218 146.274 44.218 145.447 45.0452L142.448 48.0428C141.621 48.8701 141.621 50.2132 142.448 51.0404L154.093 62.6839C154.569 63.1617 155.22 63.3638 155.842 63.2901H155.845L155.853 63.2888C156.305 63.2329 156.743 63.0308 157.09 62.6839L178.001 41.7732C178.828 40.9459 178.828 39.6028 178.001 38.7755L175.003 35.7767C174.176 34.9494 172.833 34.9494 172.006 35.7767L155.591 52.1917Z" fill="white"/>
      <defs>
      <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
      <use xlink:href="#image0" transform="translate(-0.25) scale(0.002)"/>
      </pattern>
      <radialGradient id="paint0_radial" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(99.9569 174.869) scale(99.9569 6.77804)">
      <stop stop-opacity="0.25"/>
      <stop offset="1" stop-opacity="0"/>
      </radialGradient>
      </defs>
    </svg>
    <div class="bold">Hadiah Berhasil Diklaim!</div>
    <div>Mohon tunggu konfirmasi admin Doo.id</div>
  </div>
</ion-content>
<ion-footer class="ion-no-border container split">
  <ion-toolbar>
    <ion-button mode="ios" shape="round" expand="full" color="dark" class="btn"  @click="close">Selesai</ion-button>
  </ion-toolbar>
</ion-footer>
</template>

<script>
import { IonContent, IonFooter, IonToolbar, IonButton, modalController  } from '@ionic/vue'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
    IonContent, IonFooter, IonToolbar, IonButton
  },
  methods: {
    async close() {
      await modalController.dismiss()
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
})
</script>

<style lang="scss" scoped>
h3 {
  font-size: 16px;
  font-weight: bold;
}

.btn {
  width: 100%;
  &:not(:last-child) {
    margin-right: 15px;
  }

  &.transparent {
    box-shadow: none;
    color: #000;
    &.button-round {
      --box-shadow: none !important;
    }
  }
}
.map svg {
  width: 160px;
  height: 160px;
}
</style>
